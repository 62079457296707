







































































































































































































































































































































































































































import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  VBTooltip,
} from "bootstrap-vue";
import { AvButton } from "@/components";
import { AvSkeletonInput } from "@/components/av-skeleton";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ENUMS from "@/enums";

// Services
import {
  useAdministrator,
  usePerson,
  useUser,
  useRegion,
  useDealerships,
} from "@/services";

// Interfaces
import { IOption } from "@core/services/interfaces/IUtil";
import {
  IFieldPermissions,
  IResponseGetDailyReportTypes,
  IResponseList,
} from "@core/services/interfaces/user/IUserService";
import {
  IRegionApi,
  IResponseGetRegionActive,
} from "@core/services/interfaces/covarege-area/region/IRegionService";
import {
  IResponseGetFiltersDealerships,
  IEconomicGroupFilter,
  IBusinessGroupFilter,
  IDealershipFilter,
} from "@core/services/interfaces/business/dealerships/IDealershipsService";
import {
  IBodyCreateUser,
  IResponseCreateUser,
  IResponseGetProfiles,
} from "@core/services/interfaces/administrator/IAdministratorService";

interface IUserLocal extends IBodyCreateUser {
  gruposEconomicos: number[];
  gruposEmpresas: number[];
  tipoBoletimDiario: number;
  perfilId: string;
  concessionariasIds: string[];
  regioesOperacionaisIds: string[];
}

interface IBusinessGroupsOptions extends IOption {
  economicGroupId: number | null;
}

interface IDealershipsOptions extends IOption {
  groupId: number | null;
}

@Component({
  name: "UsersAdd",
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormRadio,
    AvButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    AvSkeletonInput,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
})
export default class UsersAdd extends Vue {
  // Data
  groupTitle =
    this.$store.state["appConfig"].params.GRUPO_EMPRESA_TITLE ||
    "Grupo Empresa";
  $ENUMS = ENUMS;
  $refs = {
    refFormObserver: {} as any,
    cpfInput: {} as BFormInput,
  };
  saving = false;
  validatingCpf = false;
  validCpf = false;
  isUserAdmin: boolean =
    JSON.parse(localStorage.getItem("userData") || "").ehAdmin || false;
  userLocal: IUserLocal = {} as IUserLocal;
  show: IFieldPermissions = {} as IFieldPermissions;
  required: IFieldPermissions = {} as IFieldPermissions;
  profileOptions: IOption[] = [];
  groupsEconomicsOptions: IOption[] = [];
  businessGroups: IBusinessGroupFilter[] = [];
  businessGroupsOptions: IBusinessGroupsOptions[] = [];
  dealerships: IDealershipFilter[] = [];
  dealershipsOptions: IDealershipsOptions[] = [];
  operationalRegionOptions: IOption[] = [];
  dailyReportTypesOptions: IOption[] = [];
  loadingProfile = false;
  loadingRegion = false;
  loadingEconomicGroup = false;
  loadingGroup = false;
  loadingDealership = false;
  loadingdailyReportTypes = false;
  concessionarias: IDealershipsOptions[] = [];
  validatingEmail = false;
  validEmail = false;

  formValidation = formValidation();

  // Computeds
  get isValidCpf(): boolean | null {
    if (typeof this.userLocal.documento == "undefined") {
      return null;
    }

    return this.userLocal.documento != "" ? this.validCpf : null;
  }

  get hasEconomicGroup(): boolean {
    return this.$store.state["appConfig"].params
      .EMP_ASSOCIACAO_GRUPO_ECONOMICO == "S"
      ? true
      : false;
  }

  get isValidEmail(): boolean | null {
    if (typeof this.userLocal.email == "undefined") {
      return null;
    }

    return this.userLocal.email != "" ? this.validEmail : null;
  }

  // LifeCycle
  created() {
    this.fetchListProfiles();
    this.fetchGetFilters();
    this.fetchGetDailyReportTypes();

    if (!this.isUserAdmin) {
      this.userLocal.status = false;
    } else {
      this.fetchGetOperationalRegions();
    }
  }

  // Watch
  @Watch("userLocal.perfilId")
  getPermissions() {
    this.show = useUser.getVisualizationPermissions(
      this.userLocal.perfilId || ""
    );
    this.required = useUser.getRequiredPermissions(
      this.userLocal.perfilId || ""
    );

    if (!this.required.dealership) {
      this.userLocal.concessionariasIds = [];
    }
  }

  @Watch("businessGroups")
  updatebusinessGroupsOptions() {
    this.businessGroupsOptions = this.businessGroups.map((group) => ({
      label: group.Nome,
      value: group.Id,
      economicGroupId: group.GrupoEconomicoId,
    }));
  }

  @Watch("dealerships")
  updateDealershipsOptions() {
    this.dealershipsOptions = this.dealerships.map(
      (dealerships: IDealershipFilter) => ({
        value: dealerships.Id,
        label: dealerships.NomeFantasia,
        groupId: dealerships.GrupoEmpresaId,
      })
    );
  }

  watchGrupoEconomicoId() {
    // Verificando se os valores selecionados em grupo empresa estão contidos nas novas opções de grupo empresa
    const groups = this.businessGroups
      .filter((group) =>
        group.GrupoEconomicoId
          ? this.userLocal.gruposEconomicos.includes(group.GrupoEconomicoId)
          : false
      )
      .map((group) => group.Id);

    if (this.userLocal.gruposEmpresas) {
      this.userLocal.gruposEmpresas = this.userLocal.gruposEmpresas.filter(
        (id) => groups.includes(id)
      );
    }

    // Limpando as options de grupo empresa de acordo com os grupos econômicos selecionados
    if (this.userLocal.gruposEconomicos.length > 0) {
      this.businessGroupsOptions = this.businessGroups
        .filter((group) =>
          group.GrupoEconomicoId
            ? this.userLocal.gruposEconomicos.includes(group.GrupoEconomicoId)
            : false
        )
        .map((group) => ({
          label: group.Nome,
          value: group.Id,
          economicGroupId: group.GrupoEconomicoId,
        }));
    } else {
      this.businessGroupsOptions = this.businessGroups.map((group) => ({
        label: group.Nome,
        value: group.Id,
        economicGroupId: group.GrupoEconomicoId,
      }));
    }

    this.watchGrupoEmpresaId();
  }

  watchGrupoEmpresaId() {
    // Verificando se os valores selecionados em concessionárias estão contidos nas novas opções de concessionárias
    const dealershipsIds = this.dealershipsOptions
      .filter((dealership) =>
        dealership.groupId
          ? this.userLocal.gruposEmpresas.includes(dealership.groupId)
          : false
      )
      .map((dealership) => dealership.value);
    if (this.concessionarias) {
      this.concessionarias = this.concessionarias.filter((option) =>
        dealershipsIds.includes(option.value)
      );
    }
    // Limpando as options de concessionárias de acordo com os grupos empresas selecionados
    if (this.userLocal.gruposEmpresas.length > 0) {
      this.dealershipsOptions = this.dealerships
        .filter((dealership) =>
          dealership.GrupoEmpresaId
            ? this.userLocal.gruposEmpresas.includes(dealership.GrupoEmpresaId)
            : false
        )
        .map((dealerships: IDealershipFilter) => ({
          value: dealerships.Id,
          label: dealerships.NomeFantasia,
          groupId: dealerships.GrupoEmpresaId,
        }));
    } else {
      const groups = this.businessGroupsOptions.map((group) => group.value);
      this.dealershipsOptions = this.dealerships
        .filter((dealership) =>
          dealership.GrupoEmpresaId
            ? groups.includes(dealership.GrupoEmpresaId)
            : false
        )
        .map((dealerships: IDealershipFilter) => ({
          value: dealerships.Id,
          label: dealerships.NomeFantasia,
          groupId: dealerships.GrupoEmpresaId,
        }));
    }
  }

  // Methods
  fetchListProfiles(): void {
    this.loadingProfile = true;
    useAdministrator
      .requestGetProfiles()
      .then((response: IResponseGetProfiles) => {
        this.profileOptions = response.data.data.map((profile) => ({
          value: profile.id,
          label: profile.nome,
        }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Perfis!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        this.profileOptions = [];
      })
      .finally(() => {
        this.loadingProfile = false;
      });
  }

  fetchGetFilters() {
    this.loadingEconomicGroup = true;
    this.loadingGroup = true;
    this.loadingDealership = true;
    useDealerships
      .requestGetFilters()
      .then((response: IResponseGetFiltersDealerships) => {
        this.groupsEconomicsOptions = response.data.Data.GruposEconomicos.map(
          (groupEconomic: IEconomicGroupFilter) => ({
            value: groupEconomic.Id,
            label: groupEconomic.Nome,
          })
        );

        this.businessGroups = response.data.Data.GruposEmpresa;
        this.dealerships = response.data.Data.Empresas;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar os filtros da página!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingEconomicGroup = false;
        this.loadingGroup = false;
        this.loadingDealership = false;
      });
  }

  fetchGetOperationalRegions(): void {
    this.loadingRegion = true;
    useRegion
      .requestGetActives()
      .then((response: IResponseGetRegionActive) => {
        this.operationalRegionOptions = response.data.Data.map(
          (groupEconomic: IRegionApi) => ({
            value: groupEconomic.Id || null,
            label: groupEconomic.Nome,
          })
        );
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Regiões Operacionais!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });

        this.operationalRegionOptions = [];
      })
      .finally(() => {
        this.loadingRegion = false;
      });
  }

  fetchGetDailyReportTypes() {
    this.loadingdailyReportTypes = true;
    useUser
      .requestGetDailyReportTypes()
      .then((response: IResponseGetDailyReportTypes) => {
        this.dailyReportTypesOptions = response.data.Data.map((item) => ({
          label: item.Value,
          value: item.Key,
        }));
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar os tipos de boletim diário",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingdailyReportTypes = false;
      });
  }

  onSubmit(): void {
    this.$refs.refFormObserver
      .validate()
      .then((success: boolean) => {
        if (success) {
          this.saving = true;

          if (this.userLocal.documento) {
            this.userLocal.documento = this.userLocal.documento.replace(
              /\D/g,
              ""
            );
          }

          this.userLocal.concessionariasIds = this.concessionarias.map(
            (option) => option.value
          );

          const bodyCreateUser = {
            documento: this.userLocal.documento,
            nome: this.userLocal.nome,
            telefone: this.userLocal.telefone,
            email: this.userLocal.email,
            perfisIds: [this.userLocal.perfilId],
            status: this.userLocal.status,
            dadosAdicionais: {
              TipoBoletimDiario: this.userLocal.tipoBoletimDiario,
              ConcessionariasIds: this.userLocal.concessionariasIds,
              RegioesOperacionaisIds: this.userLocal.regioesOperacionaisIds,
              PerfisIds: [this.userLocal.perfilId],
              GrupoId: JSON.parse(localStorage.getItem("userData") || "").groupId,
            },
          };

          useAdministrator
            .requestCreateUser(bodyCreateUser)
            .then((response: IResponseCreateUser) => {
              const userId = response.data.data;

              this.$store.dispatch("firebase/addUserProcessing", userId);

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Usuário adicionado com sucesso!",
                  text: "Usuário está sendo processado e em breve seu cadastro será finalizado.",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });

              this.$router.push({
                name: "admin-panel-users-list",
              });
            })
            .catch((data: XMLHttpRequest) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    data.response.data.Errors[0] ||
                    "Erro ao tentar adicionar o usuário!",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              this.saving = false;
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Preencha todos os campos obrigatórios!",
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });

          if (document.querySelectorAll(".is-invalid").length) {
            const y =
              document
                .querySelectorAll(".is-invalid")[0]
                .getBoundingClientRect().top +
              window.scrollY -
              125;
            window.scroll({
              top: y,
              behavior: "smooth",
            });
          }
        }
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Ocorreu um erro inesperado! Acione a administração do sistema",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }

  reduceValue = (option: { label: string; value: any }): any => {
    return option.value;
  };

  checkStateCpf(validationContext: any): boolean | null {
    if (this.validatingCpf) {
      return null;
    }

    const formValidation =
      this.formValidation.getValidationState(validationContext);

    if (formValidation === null && this.isValidCpf === null) {
      return null;
    } else if (this.isValidCpf === null) {
      return formValidation;
    } else {
      return this.isValidCpf;
    }
  }

  cpfExist(cpf: string) {
    const regexApenasNumeros = /[^\d]/g;
    const cpfSanatize = (cpf || "").replace(regexApenasNumeros, "");

    if (cpfSanatize.length == 11) {
      this.validatingCpf = true;

      usePerson
        .requestValidCpfCnpj(cpf)
        .then(() => {
          this.validCpf = true;
        })
        .catch(() => {
          this.validCpf = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "CPF inválido!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.validatingCpf = false;
        });
    } else {
      this.validCpf = false;
    }
  }

  /**
   * Essa função adiciona ou remove todas as opções do select de concessionárias
   */
  toggleDealerships(value: boolean) {
    if (value) {
      this.concessionarias = this.dealershipsOptions;
    } else {
      this.concessionarias = [];
    }
  }

  checkStateEmail(validationContext: any): boolean | null {
    if (this.validatingEmail) {
      return null;
    }

    const formValidation =
      this.formValidation.getValidationState(validationContext);

    if (formValidation === null && this.isValidEmail === null) {
      return null;
    } else if (this.isValidEmail === null) {
      return formValidation;
    } else {
      return this.isValidEmail;
    }
  }

  checkEmail(isEmailValid: boolean | null) {
    if (isEmailValid) {
      // Checando se o e-mail já existe
      this.validatingEmail = true;
      useUser
        .requestList({
          draw: 1,
          length: 10,
          userdata: {
            email: this.userLocal.email,
          },
        })
        .then((response: IResponseList) => {
          const index = response.data.data.findIndex(
            (it) => it.Email == this.userLocal.email
          );

          if (index >= 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "E-mail existente",
                text: "Já existe um usuário cadastrado com o e-mail informado",
                icon: "AlertTriangleIcon",
                variant: "warning",
              },
            });

            this.validEmail = false;
          } else {
            this.validEmail = true;
          }
        })
        .finally(() => {
          this.validatingEmail = false;
        });
    }
  }
}
